import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1731055381/Akisti%20fondations/cropped-PHOTO-2021-09-12-14-37-42-removebg-preview-1_vj8gjz.png" className="App-logo" alt="logo" />
       
      </header>
      <p>
        Maintenance en cours, veuillez patienter...
      </p>
    
    </div>
  );
}

export default App;
